import { Pagination } from './core.model';
// import { userRole } from './user-role.model';

export class User {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  image_url: string;
  facebook_id: string;
  google_id: string;
  apple_id: string;
  status: string;
  role: E_role;
  password?: string;
  confirm_password?: string;
}

export class userForm {
  // id: string;
  email?: string;
  firstname: string;
  lastname: string;
  password?: string;
  phone: string;
  image_url: string;
//   facebook_id?: string;
//   google_id?: string;
//   apple_id?: string;
  role: E_role;
  status: string;
  confirm_password?: string;
}

export interface GetuserPaging {
  pagination: Pagination;
  user: User[];
}

export enum E_role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}

export class addressForm {
  name: string;
  recipient: string;
  phone: string;
  address: string;
  district: string;
  sub_district: string;
  postcode: string;
  province: string;
  country: string;
  type: string;
  isDefault: string;
}

export class address {
  id: string;
  name: string;
  recipient: string;
  phone: string;
  address: string;
  district: string;
  sub_district: string;
  postcode: string;
  province: string;
  country: string;
  type: string;
  isDefault: string;
}