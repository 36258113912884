<nz-layout class="Site">
    <!--  <nz-sider nzCollapsible [nzCollapsed]="isCollapsed" [nzTrigger]="triggerTemplate" [nzBreakpoint]="'md'">-->
    <!--    <app-side-nav></app-side-nav>-->
    <!--  </nz-sider>-->
      <nz-layout>
        <app-header></app-header>
        <nz-content class="Site-content">
            <router-outlet></router-outlet>
        </nz-content>
        <div class="subfooter com-subfotter">
          <div class="fb-customerchat" page_id="238407712994145"></div>
        </div>
        <app-footer></app-footer>
      </nz-layout>
    </nz-layout>
    
    <!-- <ng-template #trigger>
      <i nz-icon type="up"></i>
    </ng-template> -->