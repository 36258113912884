import { Injectable } from '@angular/core';
import { AES, CtyUTF8 } from '../libs/cryptojs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private passphrase = '@a&pp#f3l%$ew&ork#';

  constructor() { }

  /**
   * Saves data local
   * @param keyName string
   * @param value any
   * @param exp minutes
   */
  public saveDataLocal(keyName: string, value: any, exp: number = null): void {
    const obj = {
      data: value,
      exp: exp ? moment().tz('Asia/Bangkok').add(1, 'days').unix() : null
    };

    const encryptedValue = AES.encrypt(JSON.stringify(obj), this.passphrase).toString();
    localStorage.setItem(`${keyName.split(' ').join('+')}::${window.location.origin}`, encryptedValue);
  }

  public getDataLocal(keyName: string): any {
    // console.log('keyName',keyName);
    
    try {
      const res = localStorage.getItem(`${keyName.split(' ').join('+')}::${window.location.origin}`);

      // Check Have a value
      if (res === null) {
        return null;
      }

      const bytesData = AES.decrypt(res, this.passphrase);
      const obj = JSON.parse(bytesData.toString(CtyUTF8));

      // Check Cache Expire
      const now: number = moment().tz('Asia/Bangkok').unix();
      // if (obj.exp !== null && now > obj.exp) {
      //   return null;
      // }

      return obj.data;
    } catch {
      return null;
    }
  }

  public removeKeyLocal(keyName: string): void {
    localStorage.removeItem(`${keyName.split(' ').join('+')}::${window.location.origin}`);
  }

  public removeAllLocal(): void {
    localStorage.clear();
  }

  // Session
  public saveDataSession(keyName: string, value: any, exp: number = null): void {
    const obj = {
      data: value,
      exp: exp ? moment().tz('Asia/Bangkok').add(exp, 'minutes').unix() : null
    };

    const encryptedValue = AES.encrypt(JSON.stringify(obj), this.passphrase).toString();
    sessionStorage.setItem(`${keyName.split(' ').join('+')}::${window.location.origin}`, encryptedValue);
  }

  public getDataSession(keyName: string): any {
    try {
      const res = sessionStorage.getItem(`${keyName.split(' ').join('+')}::${window.location.origin}`);

      // Check Have a value
      if (res === null) {
        return null;
      }

      const bytesData = AES.decrypt(res, this.passphrase);
      const obj = JSON.parse(bytesData.toString(CtyUTF8));

      // Check Cache Expire
      const now: number = moment().tz('Asia/Bangkok').unix();
      // if (obj.exp !== null && now > obj.exp) {
      //   return null;
      // }

      return obj.data;
    } catch {
      return null;
    }
  }

  public removeKeySession(keyName: string): void {
    sessionStorage.removeItem(`${keyName.split(' ').join('+')}::${window.location.origin}`);
  }

  public removeAllSession(): void {
    sessionStorage.clear();
  }
}
