import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';


@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.less']
})
export class BaseLayoutComponent implements OnInit {
  triggerTemplate: TemplateRef<void> | null = null;
  @ViewChild('trigger', {static: false}) customTrigger: TemplateRef<void>;

  constructor(
    private facebookService: FacebookService
  ) { }

  ngOnInit(): void {
    this.initFacebookService();
  }
  // custom trigger can be TemplateRef
  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  // facebook init
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }

}
