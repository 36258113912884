import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { userForm } from '../../../../model/users.model';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  validateForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private msg: NzMessageService
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      confirm_password: [null, [Validators.required]],
      firstname: [''],
      lastname: [''],
      phone: [''],
      image_url: [null],
    });
  }

  submitRegister(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      const formValue = this.validateForm.value;
      let strUser = new userForm();
      strUser.email = formValue.email;
      strUser.password = formValue.password;
      strUser.confirm_password = formValue.confirm_password;
      strUser.firstname = formValue.firstname;
      strUser.lastname = formValue.lastname;
      strUser.phone = formValue.phone;
      // strUser.image_url = formValue.image_url;
      // "firstname":"Nopparat",
      // "lastname":"Wiwattanaphon",
      // "phone":"0642248248",
      // "image_url": null,
      // "role":"ADMIN"
      this.authService.register(strUser).subscribe((res) => {
        if (res) {
          setTimeout(() => {
            this.msg.success('สมัครสมาชิกสำเร็จ');
            window.location.reload();
          }, 1000);
        }
      }, (error: any) => {
        // this.message.error(error.error.message, {
        //   nzDuration: 3000
        // });
      });
    }
  }

}
