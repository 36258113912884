
<nz-header>
    <div nz-row>
        <div nz-col nzXs="16" [nzSm]="14" nzMd="14" nzLg="8" nzXl="9">
            <div class="logo">
                <a>
                <img src="./assets/icon/logo-white.png" alt="logo image" height="70">&nbsp;&nbsp;
                </a>
                <!-- <span style="color: #fff;font-size: 1.2em;"><a class="text-white" [routerLink]="['/home']">Loengnok Toys </a></span> -->
            </div>
        </div>
        <div nz-col nzXs="0" [nzSm]="0" nzMd="0" nzLg="10" [nzXl]="15" class="not-show-menu">
            <div nz-row nzType="flex" nzJustify="end" >
                <ul nz-menu nzMode="horizontal" class="header-menu">
                    <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <a class="text-white" [routerLink]="['/']">หน้าแรก</a>
                    </li>
                    <li nz-submenu nzTitle="สินค้า" class="text-white" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <ul>
                            <li nz-menu-item>
                                <a class="text-dark" [routerLink]="['/store/in-stock']" >IN STOCK</a>
                            </li>
                            <li nz-menu-item>
                                <a class="text-dark" [routerLink]="['/pre-order']" >PRE-ORDER</a>
                            </li>
                        </ul>
                    </li>
                    <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <a class="text-white" [routerLink]="['/tracking']">ติดตามพัสดุ</a>
                    </li>
                    <li nz-menu-item class="text-white" *ngIf="isLogin == true" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <a class="text-white" [routerLink]="['/payment/confirm']">แจ้งชำระเงิน</a>
                    </li>
                    <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <a class="text-white" [routerLink]="['/contact']">ติดต่อเรา</a>
                    </li>
                    <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu']" [routerLinkActiveOptions]="{exact: true}">
                        <a class="text-white" [routerLink]="['/blog']">รีวิว</a>
                    </li>
                    <li nz-menu-item class="text-white" *ngIf="isLogin == false" (click)="isVisible = true">
                        <a class="text-white" >เข้าสู่ระบบ</a>
                    </li>
                        <li nz-menu-item class="text-white" *ngIf="isLogin == true" >
                            <ng-container *ngIf="user$">
                                <a nz-dropdown class="text-white" nzTrigger="click" [nzDropdownMenu]="userMenu" [nzPlacement]="'bottomCenter'">
                                    <nz-avatar [nzSize]="40" nzSrc="{{user$.image_url}}"></nz-avatar>
                                    {{user$.firstname}} {{user$.lastname}}
                                </a>
                            </ng-container>
                        </li>
                    <li nz-menu-item class="text-white" *ngIf="isLogin == true">
                        <a class="cart text-white" [routerLink]="['/cart']">
                            <!-- <i nz-icon nzType="shopping" nzTheme="outline"></i> -->
                            <!-- ตะกร้า -->
                        </a>
                        <!-- <nz-badge [nzCount]="1">
                            <a class="cart text-white"
                            nz-popover
                            [(nzPopoverVisible)]="visible"
                            nzPopoverTrigger="click"
                            [nzPopoverContent]="contentTemplate"
                            [nzPopoverPlacement]="bottomRight"
                            >
                            </a>
                        </nz-badge> -->
                        <!-- <nz-badge [nzCount]="1">
                            <a class="cart text-white" [routerLink]="['/cart']">
                            </a>
                        </nz-badge> -->
                    </li>
                </ul>
            </div>
        </div>

        <!-- for responsive menu -->
        <div nz-col nzFlex="auto" class="hide-nav-list">
            <div nz-row nzType="flex" nzJustify="end" style="margin-top: 10px;" nz-dropdown nzTrigger="click" [nzDropdownMenu]="mainMenu" [nzPlacement]="'bottomCenter'">
                <button nz-button nzType="link">
                    <span class="material-icons md-48 text-white">
                        menu
                    </span>
                </button>
            </div>
        </div>
    </div>
</nz-header>
  
  <!-- Dropdown menu responsive -->

<nz-dropdown-menu #mainMenu="nzDropdownMenu">
    <ul nz-menu style="margin-top: 20px;" class="header-menu">
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/']">หน้าแรก</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/store/in-stock']" >สินค้า IN STOCK</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/pre-order']" >สินค้า PRE-ORDER</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/tracking']">ติดตามพัสดุ</a>
        </li>
        <li nz-menu-item class="text-white" *ngIf="isLogin == true" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/payment/confirm']">แจ้งชำระเงิน</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/contact']">ติดต่อเรา</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/blog']">รีวิว</a>
        </li>
        <li nz-menu-item class="text-white" *ngIf="isLogin == false" (click)="isVisible = true">
            <a class="text-white" >เข้าสู่ระบบ</a>
        </li>
        <ng-container *ngIf="isLogin == true">
            <li nz-menu-item class="text-white" >
                <ng-container *ngIf="user$">
                    <a nz-dropdown class="text-white" nzTrigger="click" [nzDropdownMenu]="userMenu" [nzPlacement]="'bottomCenter'">
                        <nz-avatar [nzSize]="40" nzSrc="{{user$.image_url}}" ></nz-avatar>
                        {{user$.firstname}} {{user$.lastname}}
                    </a>
                </ng-container>
            </li>
        </ng-container>
        <li nz-menu-item class="text-white" *ngIf="isLogin == true">
            <a class="text-white" [routerLink]="['/cart']">ตะกร้า</a>
        </li>
    </ul>
</nz-dropdown-menu>
  
<!-- Dropdown menu for users -->
<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu style="margin-top: 20px;" class="header-menu">
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/profile']">โปรไฟล์</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}">
            <a class="text-white" [routerLink]="['/order-history']">ประวัติการสั่งซื้อ</a>
        </li>
        <li nz-menu-item class="text-white" [routerLinkActive]="['active-menu-nav']" [routerLinkActiveOptions]="{exact: true}" *ngIf="admin == true">
            <a class="text-white" [routerLink]="['/admin']" target="_blank">แอดมิน</a>
        </li>
        <li nz-menu-item class="text-white" (click)="logout()">
            <a class="text-white" >ออกจากระบบ</a>
        </li>
    </ul>
</nz-dropdown-menu>

<nz-modal [(nzVisible)]="isVisible" [nzWidth]="'550px'" nzTitle="" nzFooter="false" (nzOnCancel)="handleCancel()">
    <ng-container *nzModalContent>
        <div class="container text-center">
            <div class="col-12 col-md-12">
                <ng-container *ngIf="isRegisPage == false">
                    <h2>เข้าสู่ระบบ</h2>
                    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                        <nz-form-item>
                          <nz-form-control nzErrorTip="กรุณากรอกอีเมลล์">
                            <nz-input-group >
                              <input class="text-left" type="email" nz-input formControlName="email" id="email" placeholder="email" nzSize="large" />
                            </nz-input-group>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-control nzErrorTip="กรุณากรอกรหัสผ่าน">
                            <nz-input-group>
                              <input class="text-left" type="password" nz-input formControlName="password" id="password" placeholder="Password" nzSize="large"/>
                            </nz-input-group>
                          </nz-form-control>
                        </nz-form-item>
                        <button nz-button class="login-form-button login-form-margin" nzType="primary" >เข้าสู่ระบบ</button>
                        <h5>หรือ</h5>
                    </form>
                    <button nz-button class="login-form-button login-form-margin" (click)="switch()">สมัครสมาชิก</button>
                    <button nz-button class="login-form-button login-form-margin" nzType="primary" (click)="flogin()"><i nz-icon nzType="facebook" nzTheme="outline"></i> เข้าสู่ระบบด้วย facebook</button>
                    <!-- <button nz-button class="login-form-button login-form-margin" >เข้าสู่ระบบด้วย google</button> -->
                </ng-container>
                <ng-container *ngIf="isRegisPage == true">
                    <app-register></app-register>
                    <h5>หรือ</h5>
                    <button nz-button class="login-form-button login-form-margin" (click)="switch()">เข้าสู่ระบบ</button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</nz-modal>