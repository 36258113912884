import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/users.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.less']
})
export class BackendComponent implements OnInit {

  isCollapsed = false;
  public user$: User;
  isLogin = false;
  admin = false;
  staff = false;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    if (await this.checkLogin()) {
      this.isLogin = true;
      this.user$ = this.authService.user;

      if (this.authService.user.role == 'ADMIN') this.admin = true;
      if (this.authService.user.role == 'STAFF') this.staff = true;
    } else {
      this.isLogin = false;
    }
  }

  async checkLogin() {
    let login = await this.authService.checkLogged();
    // console.log('login',login);
    
    return login
  }

  home(): void {
    this.router.navigate(['/home']);
  }

}
