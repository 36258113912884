// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare var require: any;
const {version: appVersion} = require('../../package.json');
export const environment = {
  name: 'dev',
  production: false,
  hmr: false,
  version: appVersion,
  // main_api: 'https://loengnoktoys-api.cube-connection.net/api',
  main_api: 'https://api.loengnoktoys.com/api',
  // main_api: 'https://loengnoktoys-api.cube-studio.net/api',
  // main_api: 'http://localhost:4001/api', 
  upload_api: 'https://api.loengnoktoys.com/api/file/upload/image',
  // upload_api: 'https://shared.sg.gateway.cube-studio.net/v1/upload/image',
  uploadKey: 'fk3B4q2sceeN8wcXfACIayPZTWMwNBv1',
  uploadAppId: 'loengnoktoys',
  // firstPage: '/app/vm-status',
  // firstPageId: 'vm_status',
  apiPwd: 'S7rWn6kJbf57jfHrjjsEVutfdY3AakJE',
  googleMap: {
    // key: 'AIzaSyBKrAIfMWxZHXg_8fN8gWxuXJfupjeEPik',
    // region: 'TH',
    // language: 'th'
  },
  features: {
    shiftReportExportMovingProduct: false,
    downloadAllProductInBasketAsPFD: false,
    excelFunctionInCreateVM: true
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
