import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CartService } from '../../../../services/cart.service';
import { User } from '../../../../model/users.model';
import { AuthService } from '../../../../services/auth.service';
import { map, switchMap, take } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  isVisible = false;
  isLogin = false;
  admin = false;
  isRegisPage = false;
  public user$: User;
  public cart$: Observable<any>;
  totalcartItems: { total: number };

  totalPrice = 0;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private cartService: CartService,
    private msg: NzMessageService

  ) { }

  switch() {
    
    if (this.isRegisPage == true) {
      this.isRegisPage = false;
    } else {
      this.isRegisPage = true;
    }
  }

  async ngOnInit() {

    // this.loadCart();
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    if (await this.checkLogin()) {
      this.isLogin = true;
      this.user$ = this.authService.user;

      if (this.authService.user.role == 'ADMIN') this.admin = true;
      if (this.authService.user.role == 'STAFF') this.admin = true;
    } else {
      this.isLogin = false;
    }
  }

  loadTotalCart() {

  }

  // loadCart() {
  //   this.cartService.getAllOrderCount().
  // }

  async checkLogin() {
    let login = await this.authService.checkLogged();
    return login
  }

  validateForm!: FormGroup;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      const formValue = this.validateForm.value;
      const body = {
        email: formValue.email,
        password: formValue.password
      };
      this.authService.login(body).subscribe((res) => {
        if (res) {
          this.authService.token = res.token;
          this.authService.getMe().subscribe((_res) => {
            //  (_res);
            
            this.authService.user = _res;
            this.checkLogin()

            setTimeout(() => {
              this.isVisible = false;
              window.location.reload();
            }, 0);
          });
        }
        
      }, (error: any) => {
          this.msg.error('ไม่สามารถเข้าสู่ระบบได้');
      });
    }
  }

  flogin() {
    console.log('fb');
    
    this.isVisible = true;
    this.authService.signInWithFB().subscribe((res) => {
      console.log(res);
      
      this.authService.loginFb(res['authToken']).subscribe((res) => {
        if (res) {
          this.authService.token = res.token;
          this.authService.getMe().subscribe((_res) => {
            this.authService.user = _res;
            this.checkLogin();
            this.isVisible = false;
            this.msg.success('เข้าสู่ระบบสำเร็จ');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }
        
      }, (error: any) => {
        this.isVisible = false;
        this.msg.error('ไม่สามารถเข้าสู่ระบบได้');

        // this.msg.warning('ไม่สามารถเข้าสู่ระบบได้');
      });
    });
    
  }

  logout() {
    this.authService.logout().then(() => {
      this.checkLogin();
      this.msg.success('ออกจากระบบแล้ว');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
