import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { User, userForm } from '../model/users.model';
// import { delayedRetry } from 'utils/libs/http-request';
import { LocalStorageService } from '../../utils/services/local-storage.service';
import { LocalKey } from '../../utils/interfaces/local-key';
import { BaseAPIEntity } from '../model/core.model';
import { ISignIn } from '../model/authentication.model';
import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
// import { LoaderService } from 'utils/services/loader.service';
import CryptoJS from 'crypto-js';
// import { ApplicationsRole, userRole } from 'app/models/user-role.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User | null>(null);
  public get user(): User {
    return this._user.value;
  }
  public set user(data: User) {
    this.localStorageService.saveDataLocal(LocalKey.User, data);
    this._user.next(data);
  }

  private _token = null;
  public get token(): string {
    return this._token;
  }
  public set token(data: string) {
    this.localStorageService.saveDataLocal(LocalKey.Token, data);
    this._token = data;
  }

  private apiPath = {
    me: 'auth/me', 
    login: 'auth/login', 
    fblogin: 'auth/login/fb', 
    refreshToken: 'auth/refresh_token',
    register: 'auth/register',
    admin_category: 'master/cat'
    // editProfile: 'auth/',
  };

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private socialService: SocialAuthService
    // private loaderService: LoaderService
  ) { }

  /**
   * Get user Observable
   * @returns {Observable<user>}
   * @memberof AuthService
   */
  public getuserObs(): Observable<User> {
    return this._user.asObservable();
  }

  public signInWithFB(): Observable<any> {
    // this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return this.socialService.authState;
  }

  public loginFb(access_token: string): Observable<any> {
    return this.httpClient.post(`${environment.main_api}/${this.apiPath.fblogin}`, {
      access_token: access_token,
    }).pipe(
      // delayedRetry(1000, 0),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * user Login
   * @param {{ email: string, password: string }} data
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public login(data: { email: string, password: string }): Observable<any> {
    return this.httpClient.post(`${environment.main_api}/${this.apiPath.login}`, {
      email: data.email,
      password: data.password
    }).pipe(
      // delayedRetry(1000, 0),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public register(data: userForm): Observable<any> {    
    return this.httpClient.post(`${environment.main_api}/${this.apiPath.register}`, {
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      // image_url: data.image_url,
      role: 'USER'
    })
    .pipe(
      map((res) => {
        //  (res);
        
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
  }

   /**
   * user Login
   * @param {{ id: string }} data
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public getMe(): Observable<any> {
    //  (this.token);
    
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: 'Bearer ' + this.token,
    //   })
    // };
    //  ('httpOptions',httpOptions);
    
    return this.httpClient.get(`${environment.main_api}/${this.apiPath.me}`)
    .pipe(
      // delayedRetry(1000, 0),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * user Logout
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.user = null;
      this.localStorageService.removeAllLocal();
      this.localStorageService.removeAllSession();
      // this.loaderService.hide();
      resolve();
    });
  }

  /**
   * Check user Logged
   * @returns {Promise<boolean>}
   * @memberof AuthService
   */
  public checkLogged(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const user = this.localStorageService.getDataLocal(LocalKey.User);
      const token = this.localStorageService.getDataLocal(LocalKey.Token);
      if (user && token) {
        this.token = token;
        this.user = user;
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Refresh Token
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public refreshToken(): Observable<any> {
    return this.httpClient.post(`${environment.main_api}/${this.apiPath.refreshToken}`, null).pipe(
      // delayedRetry(1000, 0),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Forgot Password
   * @param {string} email
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  // public forgotPassword(email: string): Observable<any> {
  //   return this.httpClient.post(`${environment.main_api}/${this.apiPath.forgotPassword}`, {
  //     email: email
  //   }).pipe(
  //     // delayedRetry(1000, 0),
  //     map((res: BaseAPIEntity<any>) => {
  //       return res.result;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  /**
   * Reset Password
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  // public resetPassword(data: any): Observable<any> {
  //   return this.httpClient.post(`${environment.main_api}/${this.apiPath.resetPassword}`, {
  //     ...data
  //   }).pipe(
  //     // delayedRetry(1000, 0),
  //     map((res: BaseAPIEntity<any>) => {
  //       return res.result;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  /**
   * Check user Has Brand
   * @returns {boolean}
   * @memberof AuthService
   */
  public userHasBrand(): boolean {
    if (this.user) {
      return true;
    }
    return false;
  }

  public hashRole(str: string): string {
    const sha512 = CryptoJS.algo.SHA512.create();
    sha512.update(str + '_' + 'y3UXfAz8jcRU2zsHhKjgrQnrRTU36L');
    return sha512.finalize().toString(CryptoJS.enc.Hex);
  }

  // public reportUserInvalidate(): Observable<any> {
  //   return of(true);
  //   return this.httpClient.post(`${environment.main_api}/${this.apiPath.reportUserInvalidate}`, {}).pipe(
  //     // delayedRetry(1000, 0),
  //     map((res: BaseAPIEntity<any>) => {
  //       return res.result;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  /**
   * Mocking Login
   *
   * @param {{ email: string, password: string }} data
   * @return {*}  {Observable<user>}
   * @memberof AuthService
   */
  // public mocklogin(data: { email: string, password: string }): Observable<ISignIn> {
  //   return this.httpClient.get(`${environment.main_api}/${this.mockApiPath.login}`, {
  //     params: {
  //       username: data.email.split('@')[0]
  //     }
  //   }).pipe(
  //     // delayedRetry(1000, 0),
  //     map((res: any) => {
  //       if (res.length < 1) {
  //         throw new Error('Email or Password incorrect.');
  //       }

  //       return {
  //         user: new User(res[0]),
  //         Authorization: 'MockToken',
  //         signKey: null
  //       } as ISignIn;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }
}
