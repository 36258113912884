<h2>สมัครสมาชิก</h2>
<form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitRegister()">
    <nz-form-item>
        <nz-form-control nzErrorTip="กรุณากรอกอีเมลล์">
        <nz-input-group >
            <input class="text-left" type="email" nz-input formControlName="email" id="email" placeholder="email" nzSize="large" />
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control nzErrorTip="กรุณากรอกรหัสผ่าน">
        <nz-input-group>
            <input class="text-left" type="password" nz-input formControlName="password" id="password" placeholder="Password" nzSize="large"/>
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control nzErrorTip="กรุณากรอกรหัสผ่าน">
        <nz-input-group>
            <input class="text-left" type="password" nz-input formControlName="confirm_password" id="confirm_password" placeholder="Confirm Password" nzSize="large"/>
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control>
        <nz-input-group>
            <input class="text-left" type="text" nz-input formControlName="firstname" id="firstname" placeholder="Firstname" nzSize="large"/>
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control>
        <nz-input-group>
            <input class="text-left" type="text" nz-input formControlName="lastname" id="lastname" placeholder="Lastname" nzSize="large"/>
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control>
        <nz-input-group>
            <input class="text-left" type="text" nz-input formControlName="phone" id="phone" placeholder="Phone" nzSize="large"/>
        </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    <button nz-button class="login-form-button login-form-margin" nzType="primary" >สมัครสมาชิก</button>
    <!-- <h5>หรือ</h5> -->
    </form>
<!-- <button nz-button class="login-form-button login-form-margin">เข้าสู่ระบบ</button> -->