import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { FacebookModule } from 'ngx-facebook';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutModule,
    FacebookModule.forRoot()
  ]
})
export class CoreModule { }
