import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of, Subject } from 'rxjs';
import { take, map, switchMap, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Home } from '../model/home.model';
// import { delayedRetry } from 'utils/libs/http-request';
// import { LocalStorageService } from '../../utils/services/local-storage.service';
// import { LocalKey } from '../../utils/interfaces/local-key';
// import { BaseAPIEntity } from '../model/core.model';
// import { ISignIn } from '../model/authentication.model';
// import { LoaderService } from 'utils/services/loader.service';
// import CryptoJS from 'crypto-js';
// import { ApplicationsRole, userRole } from 'app/models/user-role.model';
@Injectable({
  providedIn: 'root'
})
export class CartService {

  private _home = new BehaviorSubject<any | null>(null);
  public obj$ = new BehaviorSubject<any[]>([]);
  public cartItems = [];
  public products = new Subject();

  private apiPath = {
    mycart: 'sale/cart',
    orderCount: 'sale/cart/order/count',
    payment_confirm: 'sale/cart/payment_confirm',
    eOrder: 'e-order/admin/cart'
  };

  constructor(
    private httpClient: HttpClient,
    // private loaderService: LoaderService
  ) { }

  /**
   * Get user Observable
   * @returns {Observable<User>}
   * @memberof HomeService
   */
  public getuserObs(): Observable<any> {
    return this._home.asObservable();
  }

  public getMyCart(): Observable<any> {
    return this.httpClient.get(`${environment.main_api}/${this.apiPath.mycart}`);
    // .pipe(
    //   map((res) => {
        
    //     return res;
    //   }),
    //   catchError((error) => {
    //     return throwError(error);
    //   })
    // )
  }

  public getCartById(id: string ): Observable<any> {
    
    return this.httpClient.get(`${environment.main_api}/${this.apiPath.eOrder}/${id}`)
    .pipe((
      take(1),
      map((res) => {
        
        return res
      }),
      catchError((error) => {
        return throwError(error);
      })
    ))
    
  }

  public addProductToCart(product_id: string, qty: number, types: string): Observable<any> {
    return this.httpClient.put(`${environment.main_api}/${this.apiPath.mycart}`, {
      itemId: product_id,
      qty: qty,
      type: types
    })
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
  }

  public checkoutShoppingCart(cartId: string, payment: string, promotionCode: string, deliveryInfo: string, deliveryAddress: string, deliveryContact: string, deliveryEmail: string): Observable<any> {
    return this.httpClient.post(`${environment.main_api}/${this.apiPath.mycart}`, {
      cartId: cartId,
      payment: payment,
      promotionCode: promotionCode,
      deliveryInfo: deliveryInfo,
      deliveryAddress: deliveryAddress,
      deliveryContact: deliveryContact,
      deliveryEmail: deliveryEmail
    })
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
  }

  public removeProductFromCart(itemId: string, product_id: string): Observable<any> {
    return this.httpClient.delete(`${environment.main_api}/${this.apiPath.mycart}/${itemId}?itemId=${product_id}`)
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
  }

  public getAllOrderCount(): Observable<any> {
    return this.httpClient.get(`${environment.main_api}/${this.apiPath.orderCount}`)
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    )
  }

  public getObs(): Observable<any> {
      return this.obj$.asObservable().pipe(
        switchMap((data) => {
          if (data.length > 0) {
            return of(data);
          }
          // return this.getAllProduct();
          return this.getMyCart();
        })
      );
  }

  public reset(): Promise<any> {
    return new Promise((reslove, reject) => {
      this.obj$.next([]);
      reslove(true);
    });
  }

  
}
