<nz-layout style="height: 100%; min-height: auto;">
  <nz-sider nzCollapsible [nzCollapsedWidth]="0" [(nzCollapsed)]="isCollapsed" nzBreakpoint="lg" [nzTrigger]="null" nzTheme="light">
    <div class="logo text-dark text-center" ><a class="text-dark" (click)="home()"><img src="../../assets/icon/logo-black.png" alt="" height="55"></a></div>
    <ul nz-menu nzTheme="light" nzMode="inline">
      <li nz-menu-item [routerLink]="['/admin/dashboard']" [routerLinkActive]="['active-menu']">
          <i nz-icon nzType="dashboard"></i>
          <span>แดชบอร์ด</span>
      </li>
      <!-- <li nz-submenu nzTitle="จัดการออเดอร์" nzIcon="shopping" [routerLinkActive]="['active-menu']">
        <ul>
          <li nz-menu-item [routerLink]="['/admin/order']" >จัดการออเดอร์</li>
          <li nz-menu-item [routerLink]="['/admin/order']" >รายการแจ้งชำระ</li>
        </ul>
      </li> -->
      <li nz-menu-item [routerLink]="['/admin/order']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="shopping" nzTheme="outline"></i>
        <span>จัดการออเดอร์</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/payment']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="pay-circle" nzTheme="outline"></i>
        <span>รายการชำระเงิน</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/category']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="tag" nzTheme="outline"></i>
        <span>จัดการหมวดหมู่</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/stock']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="barcode" nzTheme="outline"></i>
        <span>จัดการสินค้า</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/promotion']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="dollar" nzTheme="outline"></i>
        <span>จัดการโปรโมชัน</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/blog']" [routerLinkActive]="['active-menu']">
        <i nz-icon nzType="pic-left" nzTheme="outline"></i>
        <span>จัดการบทความ</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/staff']" [routerLinkActive]="['active-menu']" *ngIf="admin == true">
        <i nz-icon nzType="user" nzTheme="outline"></i>
        <span>จัดการสมาชิก</span>
      </li>
      <li nz-menu-item [routerLink]="['/admin/report']" [routerLinkActive]="['active-menu']" *ngIf="admin == true">
        <i nz-icon nzType="line-chart" nzTheme="outline"></i>
        <span>รายงาน</span>
      </li>
      <li nz-submenu nzTitle="ตั้งค่า" nzIcon="shop" [routerLinkActive]="['active-menu']" *ngIf="admin == true">
        <ul>
          <li nz-menu-item [routerLink]="['/admin/setting']" >ข้อมูลร้าน</li>
          <li nz-menu-item [routerLink]="['/admin/setting/slideshow']" >SlideShow</li>
          <li nz-menu-item [routerLink]="['/admin/setting/bank']" >ข้อมูลธนาคาร</li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout >
    <nz-header>
      <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>