import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackendComponent } from './backend/backend.component';
import { BaseLayoutComponent } from './core/layout/pages/base-layout/base-layout.component';

const routes: Routes = [
  {
    path: '', component: BaseLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./frontend/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'order',
        loadChildren: () => import('./frontend/order/order.module').then(m => m.OrderModule),
      },
      {
        path: 'store',
        loadChildren: () => import('./frontend/store/store.module').then(m => m.StoreModule),
        // data: {
        //   useMiddleMeta: true,
        //   title: 'เกี่ยวกับเรา Kapajum.com',
        //   ogUrl: 'kapajum.com',
        //   description: 'ขาประจำ.com ระบบ Delivery ครบวงจร',
        //   keywords: 'ขาประจำ, Delivery, สั่งอาหารใกล้บ้าน'
        // }
        // this.title.setTitle('Longnok Toys Store - จำหน่าย โมเดล ฟิกเกอร์ เรซิ่น');
        // this.meta.updateTag({property: 'og:title', content: 'Longnok Toys Store - จำหน่าย โมเดล ฟิกเกอร์ เรซิ่น'});
        // this.meta.updateTag({name: 'description', content: 'Longnok Toys Store,loengnoktoys,จำหน่ายโมเดล,ฟิกเกอร์,เรซิ่น,โมเดล,ดรากอนบอล'});
        // // 
        // this.meta.updateTag({property: 'og:image', content: 'https://loengnoktoys-public.s3.amazonaws.com/images/2021-04-10_026cacd0-845d-4b12-90bc-53584f16e7ed.png'});
        // this.meta.updateTag({property: 'og:url', content: `https://loengnoktoys.com/`});

      },
      {
        path: 'pre-order',
        loadChildren: () => import('./frontend/store/store.module').then(m => m.StoreModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./frontend/contact/contact.module').then(m => m.ContactModule),
        data: {
          useMiddleMeta: true,
          title: 'Longnok Toys Store - จำหน่าย โมเดล ฟิกเกอร์ เรซิ่น',
          ogUrl: 'https://loengnoktoys.com',
          description: 'Longnok Toys Store,loengnoktoys,เลิงนกทอยส์,จำหน่ายโมเดล,ฟิกเกอร์,เรซิ่น,โมเดล,ดรากอนบอล',
          keywords: 'Longnok Toys Store,loengnoktoys,เลิงนกทอยส์,จำหน่ายโมเดล,ฟิกเกอร์,เรซิ่น,โมเดล,ดรากอนบอล'
        }
      },
      {
        path: 'payment',
        loadChildren: () => import('./frontend/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'blog',
        loadChildren: () => import('./frontend/blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./frontend/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'order-history',
        loadChildren: () => import('./frontend/order-history/order-history.module').then(m => m.OrderHistoryModule)
      },
      {
        path: 'tracking',
        loadChildren: () => import('./frontend/tracking/tracking.module').then(m => m.TrackingModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./frontend/cart/cart.module').then(m => m.CartModule)
      },
      {
        path: 'policy',
        loadChildren: () => import('./frontend/policy/policy.module').then(m => m.PolicyModule)
      }
    ]
  },
  {
    path: 'admin', component: BackendComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./backend/backend.module').then(m => m.BackendModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
